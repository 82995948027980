<template>
  <div id="features_container">
    <HOMEHEADER ref="headerRef" :isPC="isPC" @goAnchor="goAnchor" :scrollOptical="495" sourceType="offical" />
    <div class="top_container">
      <div class="text_box">
        <div v-if="!isPC" class="top_title">
          <img :src="require('@/assets/images/about/mobileTitle.png')" />
        </div>
        <p>国内领先的saas服务平台</p>
        <p>帮助企业走向数字化转型新时代</p>
        <!--<el-button v-if="isPC" class="tryBtn" type="primary" @click="$router.push({ name: 'REGISTER' })">
          立即试用
        </el-button>-->
      </div>
    </div>
    <div class="company_box">
      <div class="company">
        <div v-if="isPC" class="company_logo">
          <img :src="require('@/assets/images/about/companyBg1.png')" />
        </div>
        <div class="title">关于公司</div>
        <p class="text">
          上海乙维智能科技有限公司是国内领先的设备售后一站式服务供应商，乙维旗下核心产品-医修保，是基于自身10多年的售后服务经验而推出的saas服务应用，
          致力于通过售后全流程化端到端闭环管理,为服务商与机构之间搭建一条高效的连接桥梁。<span v-if="isPC">降低沟通成本，增强服务效能，从而帮助厂家及服务商提升设备售后服务管理及数字化能力。</span>
        </p>
        <p class="text">立足当下，医修保已累计为</p>
        <div class="num_ul">
          <div class="num_li">
            <div class="top">
              <span class="num">5,000+</span>
              家
            </div>
            <div class="bottom">服务商提供服务</div>
          </div>
          <div class="num_li">
            <div class="top">
              <span class="num">10,000+</span>
              位
            </div>
            <div class="bottom">工程师的选择</div>
          </div>
          <div class="num_li">
            <div class="top">
              <span class="num">2,000+</span>
              家
            </div>
            <div class="bottom">医院的信赖</div>
          </div>
          <div class="num_li">
            <div class="top">
              <span class="num">10万+</span>
              条
            </div>
            <div class="bottom">知识库整合</div>
          </div>
        </div>
        <p class="text">
          放眼未来，医修保将坚持做好设备售后服务的良医，成为更多客户的选择，为更多的设备售后服务保驾护航！
        </p>
      </div>
    </div>
    <div class="strength_box">
      <div class="strength">
        <div class="title">技术实力</div>
        <div class="strength_desc">
          <div v-if="isPC" class="left">
            <div class="list">
              <div class="title_in">认证</div>
              <p>拥有国家高新技术企业、江苏省民营科技企业、江苏省软件企业等多项资质认证，</p>
              <p>拥有CMMI3级认证、国家信用等级AAA证书，收到多家服务客户感谢信，企业实力雄厚。</p>
            </div>
            <div class="list">
              <div class="title_in">专利</div>
              <p>拥有专利和软件著作权30余项，研发实力强，同时形成了专利保护池。</p>
            </div>
            <div class="list">
              <div class="title_in">安全</div>
              <p>数据安全性方面，已通过国家信息安全等级保护三级认证、ISO 27001认证、渗透性测试等。</p>
            </div>
          </div>
          <div class="strength_text_img" v-if="!isPC">
            <img :src="require('@/assets/images/about/strengthMobileBg.png')" />
          </div>
          <div class="right wow slideInRight" data-wow-duration="1s">
            <img :src="require('@/assets/images/about/strengthBg.png')" />
          </div>
        </div>
      </div>
    </div>
    <div class="contact_us_box wow fadeInUp">
      <div class="contact_us">
        <div class="title">联系我们</div>
        <div v-if="isPC" class="map_box">
          <div class="map_img">
            <img :src="activeMapurl" />
          </div>
          <div
            v-for="(item, index) in cloneContact"
            :key="item.mapurl"
            :class="['map_li', activeIndex === index ? 'active' : '']"
            @click="activeMap(item, index)"
          >
            <div class="address_title">{{ item.title }}</div>
            <div class="text" v-for="(txt, ind) in item.texts" :key="ind">{{ txt }}</div>
          </div>
        </div>
        <div v-if="!isPC" class="map_box">
          <div class="map_img">
            <img :src="activeMapurl" />
          </div>
          <div class="map_container">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="上海运营中心" name="0">
                <div class="map_ul">
                  <div class="map_li">
                    <div class="label">公司地址：</div>
                    <div class="text">上海市普陀区怒江北路449弄A区3号楼</div>
                  </div>
                  <div class="map_li">
                    <div class="label">咨询热线：</div>
                    <div class="text">021-5281 3076</div>
                  </div>
                  <div class="map_li">
                    <div class="label">服务邮箱：</div>
                    <div class="text">kefu-yixiubao@ajmd-med.com</div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="苏州运营中心" name="1">
                <div class="map_ul">
                  <div class="map_li">
                    <div class="label">公司地址：</div>
                    <div class="text">江苏省苏州市工业园区金鸡湖大道1335号国际科技园四期A0605</div>
                  </div>
                  <div class="map_li">
                    <div class="label">咨询热线：</div>
                    <div class="text">021-5281 3076</div>
                  </div>
                  <div class="map_li">
                    <div class="label">服务邮箱：</div>
                    <div class="text">kefu-yixiubao@ajmd-med.com</div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isPC" class="qrcode_box wow fadeInUp">
      <div class="text_container">
        <h2>医修保</h2>
        <p class="desc">
          提供专业的售后一站式解决方案及产品服务，智能化端到端服务管理，帮助厂家及服务商通过科技提升服务效率，以更好的工具管理团队，以更强的能力服务客户，以更满的姿态迎接数字化转型新时代。
        </p>
        <!--<div class="try_btn" @click="goMobileHandle">立即试用</div>-->
        <div class="pic_box">
          <img :src="require('@/assets/images/pcHome/registerQR.jpg')" />
          <p>扫描二维码</p>
          <p>立即注册</p>
        </div>
      </div>
    </div>
    <footerNav v-if="isPC" @change-tab="changeTab" sourcePage="about" />
    <div class="footer_box">
      <a href="tel:021-52813076" v-if="!isPC" class="tel_btn">咨询热线：021-5281 3076</a>
      <footerBox />
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import { WOW } from 'wowjs';
import HOMEHEADER from '../HomeComponents/homeHeader.vue';
import footerNav from '../HomeComponents/footerNav.vue';
import footerBox from '../HomeComponents/footerBox.vue';
import contactJson from './aboutUs.js';
const cloneContact = _.cloneDeep(contactJson);
cloneContact.forEach(i => {
  i.mapurl = require(`@/assets/images/about/${i.mapurl}`);
});

export default {
  components: {
    HOMEHEADER,
    footerNav,
    footerBox,
  },
  data () {
    return {
      activeName: '0',
      activeIndex: 0,
      cloneContact,
      activeMapurl: cloneContact[0].mapurl,
      isPC: true,
      currentPageW: document.documentElement.clientWidth || document.body.clientWidth,
    };
  },
  created () {
    window.addEventListener('resize', _.debounce(this.resizeHandle, 100), true);
  },
  mounted () {
    this.$nextTick(() => {
      this.resetScrollTop();
      this.$refs.headerRef.resetActive(3);
      new WOW({
        boxClass: 'wow', // default
        animateClass: 'animated', // default
        offset: 0, // default
        mobile: true, // default
        live: true, // default
      }).init();
    });
  },
  methods: {
    resetScrollTop () {
      if (document.documentElement.scrollTop) document.documentElement.scrollTop = 0;
      if (document.body.scrollTop) document.body.scrollTop = 0;
    },
    handleClick ({ name }) {
      this.activeMapurl = this.cloneContact[+name].mapurl;
    },
    // 跳转H5端
    goMobileHandle () {
      const url = process.env.VUE_APP_BASE_URL + 'xiaozi-xmb/wireless/open/miniapp/redirectUrlLink';
      window.location.href = url;
    },
    activeMap (item, index) {
      if (this.activeIndex !== index) {
        this.activeIndex = index;
        this.activeMapurl = item.mapurl;
      }
    },
    resizeHandle () {
      this.currentPageW = document.documentElement.clientWidth || document.body.clientWidth;
    },
    goAnchor (id) {
      document.querySelector(id)?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    },
    changeTab (tabName) {
      this.tabName = tabName;
    },
  },
  watch: {
    currentPageW: {
      immediate: true,
      handler (val) {
        // 可视区域大于1200视为PC样式
        this.isPC = val > 1200;
      },
    },
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeHandle, true);
  },
};
</script>
<style lang="scss" scoped>
$mobileW: 1200px;
@media (min-width: $mobileW) {
  .top_container {
    height: 500px;
    background: url('../../assets/images/about/bannerMobile.png');
    background-size: 100% 100%;
    color: #fff;
    overflow: hidden;
    .text_box {
      text-align: center;
      margin-top: 175px;
      p {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 1px;
      }
      .tryBtn {
        width: 138px;
        border-radius: 4px;
        margin-top: 36px;
      }
    }
  }
  .company_box {
    background: #fff;
    .company {
      position: relative;
      width: 960px;
      min-height: 530px;
      margin: auto;
      top: -29px;
      background: url('../../assets/images/about/companyBg.png');
      background-size: cover;
      padding: 60px 91px 10px 91px;
      box-sizing: border-box;
      .company_logo {
        position: absolute;
        top: 73px;
        right: -44px;
        width: 118px;
        height: 118px;
        img {
          display: block;
          width: 100%;
        }
      }
      .title {
        text-align: center;
        font-size: 28px;
        font-weight: 400;
        color: #222426;
        line-height: 28px;
        margin-bottom: 40px;
      }
      .text {
        font-size: 16px;
        font-weight: 400;
        color: #4a4e51;
        line-height: 32px;
        margin-bottom: 24px;
      }
      .num_ul {
        display: flex;
        color: rgba(15, 137, 255, 1);
        margin-bottom: 15px;
        .num_li {
          flex: 1;
          .top {
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            margin-bottom: 12px;
            .num {
              font-size:42px;
              font-weight: 500;
              line-height: 42px;
            }
          }
        }
      }
    }
  }
  .strength_box {
    background: #fff;
    padding-top: 15px;
    padding-bottom: 40px;
    .strength {
      width: 1200px;
      margin: auto;
      .title {
        text-align: center;
        font-size: 28px;
        font-weight: 400;
        color: #222426;
        line-height: 28px;
        margin-bottom: 40px;
      }
      .strength_desc {
        height: 460px;
        display: flex;
        text-align: left;
        justify-content: space-around;
        .left {
          display: flex;
          width: 424px;
          flex-direction: column;
          align-content: stretch;
          justify-content: space-between;
          .list {
            width: 424px;
            flex-shrink: 0;
            box-sizing: border-box;
            padding: 16px 32px 22px;
            background: #ffffff;
            box-shadow: 0px 0px 8px 0px rgba(108, 146, 233, 0.34), inset 0px -1px 2px 0px rgba(99, 136, 232, 0.15),
              inset 0px 1px 1px 0px rgba(255, 255, 255, 0.5);
            .title_in {
              font-size: 20px;
              font-weight: 600;
              color: #242832;
              line-height: 20px;
              margin-bottom: 14px;
            }
            p {
              font-size: 16px;
              font-weight: 400;
              color: #4a4e51;
              line-height: 28px;
            }
          }
        }
        .right {
          width: 650px;
          img {
            display: block;
            width: 100%;
          }
        }
      }
    }
  }
  .contact_us_box {
    height: 428px;
    background: #f6f8ff;
    .contact_us {
      width: 1200px;
      margin: auto;
      padding: 80px 0 60px;
      .title {
        text-align: center;
        font-size: 28px;
        font-weight: 400;
        color: #222426;
        line-height: 28px;
        margin-bottom: 40px;
      }
      .map_box {
        display: flex;
        justify-content: space-around;
        .map_img {
          width: 400px;
          flex-shrink: 0;
          img {
            display: block;
            width: 100%;
          }
        }
        .map_li {
          box-sizing: border-box;
          width: 364px;
          flex-shrink: 0;
          padding: 16px 32px;
          .address_title {
            font-size: 20px;
            font-weight: 600;
            color: #242832;
            line-height: 20px;
            margin-bottom: 16px;
          }
          .text {
            font-size: 16px;
            font-weight: 400;
            color: #4a4e51;
            line-height: 24px;
            margin-bottom: 12px;
            &:last-child {
              margin-bottom: 0;
            }
          }
          &.active {
            background: #ffffff;
            box-shadow: 0px 0px 8px 0px rgba(108, 146, 233, 0.34), inset 0px -1px 2px 0px rgba(99, 136, 232, 0.15),
              inset 0px 1px 1px 0px rgba(255, 255, 255, 0.5);
          }
        }
      }
    }
  }
  .footer_box {
    text-align: center;
    background: #1f1f1f;
    color: #a0aab1;
    font-size: 16px;
  }
}
@media (max-width: $mobileW) {
  #features_container {
    overflow: hidden;
  }
  .top_container {
    height: 150px;
    margin-top: 56px;
    overflow: hidden;
    background: url('../../assets/images/about/bannerMobile.png');
    background-size: 100% 100%;
    color: #fff;
    overflow: hidden;
    text-align: center;

    .text_box {
      .top_title {
        margin: 45px auto 11px;
        width: 72px;
        img {
          display: block;
          width: 100%;
        }
      }
      p {
        font-size: 8px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 1px;
        margin-bottom: 4px;
      }
    }
  }
  .qrcode_box {
    position: relative;
    color: #ccd4dc;
    padding-top: 30px;
    padding-bottom: 1px;
    background: url('../../assets/images/mobileHome/bg41.png') no-repeat, #022b52;
    background-size: contain;
    min-height: 400px;
    z-index: 20;
    &::after {
      position: absolute;
      display: block;
      content: ' ';
      z-index: 11;
      width: 0;
      height: 0;
      border-top: 60px solid #052c51;
      border-right: 50vw solid transparent;
      border-left: 50vw solid transparent;
    }
    .text_container {
      margin: 0 45px;
      text-align: center;
      h2 {
        font-size: 20px;
        margin-bottom: 16px;
      }
      .desc {
        text-align: left;
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 16px;
      }
      .try_btn {
        border: 1px solid #fff;
        height: 44px;
        line-height: 44px;
        color: #ffffff;
        border-radius: 2px;
        letter-spacing: 2px;
        font-size: 16px;
        margin-bottom: 16px;
      }
      .pic_box {
        img {
          display: block;
          width: 160px;
          height: 160px;
          margin: auto;
          margin-bottom: 20px;
        }
        p {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
  .footer_box {
    position: relative;
    box-sizing: border-box;
    padding-top: 72px;
    text-align: center;
    height: 205px;
    background: url('../../assets/images/mobileHome/bg5.png');
    background-size: contain;
    .tel_btn {
      text-decoration: none;
      display: block;
      height: 60px;
      margin: 0 17px;
      line-height: 60px;
      text-align: center;
      background: #0f89ff;
      color: #fff;
      font-size: 16px;
    }
  }
  .company_box {
    background: url('../../assets/images/about/companyBgMobile.png') no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    position: relative;
    top: -22px;
    // background-position: 0 100%;
    .company {
      margin: 24px 24px 30px;
      .title {
        margin: 24px auto 19px;
        text-align: center;
        color: #222426;
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 2px;
      }
      .text {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4a4e51;
        line-height: 25px;
        margin-bottom: 12px;
      }
      .num_ul {
        text-align: left;
        display: flex;
        justify-items: center;
        flex-wrap: wrap;
        justify-content: space-around;
        font-size: 14px;
        font-weight: 400;
        color: #0f89ff;
        line-height: 14px;
        .num_li {
          margin-bottom: 12px;
          .top {
            margin-bottom: 7px;
            .num {
              font-size: 30px;
              font-weight: 500;
              color: #0f89ff;
              line-height: 30px;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
  .strength_box {
    background: url('../../assets/images//about/strengthMobileLogo.png') no-repeat;
    background-size: 45% 45%;
    background-position: 0 25%;
    padding-top: 7px;
    padding-bottom: 10px;
    .strength {
      margin: auto;
      .title {
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        color: #222426;
        line-height: 20px;
        letter-spacing: 2px;
        margin-bottom: 14px;
      }
      .strength_desc {
        padding: 0 15px;
        .strength_text_img {
          margin-bottom: 20px;
        }
        .right {
          width: 100%;
        }
        img {
          display: block;
          width: 100%;
        }
      }
    }
  }
  .contact_us_box {
    padding-top: 20px;
    .contact_us {
      overflow: hidden;
      .title {
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        color: #222426;
        line-height: 20px;
        letter-spacing: 2px;
        margin-bottom: 16px;
      }
      .map_img {
        margin: 12px;
        margin-bottom: 25px;
        img {
          display: block;
          width: 100%;
        }
      }
      .map_container {
        /deep/ .el-tabs {
          .el-tabs__header {
            margin: 0 12px;
            // .el-tabs__item {
            //   width: 53px;
            //   margin-right: 40px;
            // }
            .el-tabs__active-bar {
              width: 33px;
            }
          }
          .el-tabs__nav-wrap::after {
            display: none;
          }
          .el-tabs__content {
            padding: 12px;
            .map_ul {
              background: #ffffff;
              box-shadow: 0px 0px 8px 0px rgba(108, 146, 233, 0.34);
              border-radius: 8px;
              padding: 16px 12px;
              .map_li {
                display: flex;
                margin-bottom: 12px;
                font-size: 14px;
                font-weight: 400;
                color: #4a4e51;
                line-height: 20px;
                .label {
                  width: auto;
                  flex-shrink: 0;
                }
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
