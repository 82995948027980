export default [
  {
    mapurl: 'shanghaimap.png',
    title: '上海运营中心',
    texts: [
      '地址：上海市普陀区怒江北路449弄A区3号楼',
      '咨询热线：021-5281 3076',
      '服务邮箱：kefu-yixiubao@ajmd-med.com',
    ],
  },
  {
    mapurl: 'suzhoumap.png',
    title: '苏州运营中心',
    texts: [
      '地址： 江苏省苏州市工业园区金鸡湖大道1335号国际科技园四期A0605',
      '咨询热线：021-5281 3076',
      '服务邮箱：kefu-yixiubao@ajmd-med.com',
    ],
  },
];
